// Globale Stile
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** Globale Einstellungen *************************************************
html {
	font-size: 62.5%;	// Basis auf 10px setzen
}

body {
	@include font-size(12);
	color: $textColor;
	line-height: 110%;
}

a {
	color: $cyan;

	&.external {
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAYAAADABlfOAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAEaSURBVHjaYmSY91CKgYEhDYhDgViLAT+4BsSrgXgWQ5L8M1yKAAKIBWpgPQNxQAtJbQMuRQABxAR1IQgEADEjHswKxKlQtaH4bAYIIEag9//D2Ujgf6IcpuL5j0AG/4LKMyKJg1zdAcQ/QHyAAGJhIAEADfqNbjkU1EMtawNxAAIIp0tBZjCQBn4CsRcQ7wMIICYG6gF2IJ4PxI4AAURNQ0FACIglAQKIhYoGgsI0Bog3AgQQC55IYSTWNGDsgyIwD2QgiA8QQCS5FKiZC0h9xWJpNjiXQQFAALFAs54WNGEvhqU1oAHoZvIDcSYsuzIyonhkFjIHIICYoHkZJvEdmpSw4Q9A3A5VuxqfjwACiAXJFtIKFDwAIMAAoCZASl81okIAAAAASUVORK5CYII=) no-repeat;
		padding-left: 22px;
	}

	&.internal {
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAYAAADABlfOAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAEeSURBVHjaYmSY91CKgYEhDYhDgViLAT+4BsSrgXgWQ5L8M3TJ////g2mAAGKBGljPQBzQQlLbgEsRQACxQF0IAgFA2zfiNG7eQ5DaBCCeDdWD01CAAGKCexndwHkPOYAYoTFJ/g+QXIjkYpwAIIBY8MgVQb2KbPBvIMlIKIwAAogFh1edgGQdlP2fgXgAthAggJiwGOgIJOcDMTsDmQAggJiwiEkCsRADBQAggDANTZJfBiRjgPgXuYYCBBAkzLCF27yH6UD8mxSjQIkfhAECCHfsJ8nPxLBs3kMuIPkVKo8zFQAEEBM064E0pILTJqrBs5AM5AeSeUjZFScACCAWaF6uB+dnEJ73kBifrsYnCRBALFDDGEguUPAAgAADAL26ShnyUCuHAAAAAElFTkSuQmCC) no-repeat;
		padding-left: 22px;
	}

	&.more {
		@extend .arrow-small-cyan-right;
		font-family: DINWeb-Bold, Arial, Helvetica, sans-serif;
		padding-left: 23px;
		text-decoration: none;
		text-transform: uppercase;

		&:hover, &:active, &:focus {
			text-decoration: underline;
		}
	}

	&.more-white {
		@extend .arrow-small-white-right;
		color: #fff;
	}
}

b {
	//font-weight: normal;
}

p {
	@include line-height(18);
	margin: 0 0 15px;
}

small {
	font-size: inherit;
}


// ***** Inhalt zentrieren *****************************************************
.site-center {
	margin: 0 auto;
	width: $siteWidth;
}

.horizontal-center {
	text-align: center;
}



// **** Skip-Links (Barrierearm) ***********************************************
.skip-links {
	a, a:link, a:visited, a:hover {
		height: 1px;
		overflow: hidden;
		width: 1px;
		position: absolute;
		left: 0;
		top: -100px;
	}

	a:active, a:focus {
		background-position: left center;
		display: block;
		height: auto;
		padding: 5px 0;
		position: static;
		width: auto;
	}
}



// ***** Überschriften *********************************************************
h2,
.h1,
.h2 {
	@include font-size(18);
	@include line-height(20);
}

.h1 {
	color: $cyan;
	font-family: DINWeb-Bold, Arial, Helvetica, sans-serif;
	font-weight: normal;
	margin: 0 0 15px;

	&:first-child {
		margin-top: 0;
	}
}

h2,
.h2 {
	font-family: DINWeb, Arial, Helvetica, sans-serif;
	font-weight: normal;
	margin: 20px 0;
}

.h3 {
	@include font-size(14);
	font-weight: normal;
	line-height: 1.3;
	margin: 20px 0;
}

.abstract {
	@include font-size(18);
	@include line-height(20);
	color: $cyan;
	font-family: DINWeb, Arial, Helvetica, sans-serif;
	font-weight: normal;
}

// Stellenbezeichnung bei den Stellenausschreibungen
.job-desc {
	@extend .abstract;
	text-align: center;
}

.cyan {
	color: $cyan;
}



// ***** Trenner (für <hr>) ****************************************************
.divider {
	border: none;
	border-top: 1px solid #fff;
	border-bottom: 1px dotted #c2c2c1;
	margin: 20px -51px;
}

.divider-reverse {
	border-top: 1px dotted #c2c2c1;
	border-bottom: 1px solid #fff;
}



// ***** Text nicht auswählbar *************************************************
.unselectable {
	@include user-select(none);
}



// ***** Silbentrennung ********************************************************
.hyphens {
	 @include hyphens;
}



// ***** Abstände **************************************************************
@for $i from 0 through 9 {
	.margin-bottom-#{5 * $i} {
		margin-bottom: 5px * $i !important;
	}

	.margin-top-#{5 * $i} {
		margin-top: 5px * $i !important;
	}

	.padding-bottom-#{5 * $i} {
		padding-bottom: 5px * $i !important;
	}

	.padding-top-#{5 * $i} {
		padding-top: 5px * $i !important;
	}
}



// ***** Pfeile ****************************************************************
.arrow-big-brown-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGhSURBVHjaYjxcYM9ABVBOpLpZQPyeUssAAoiFgTpgNRDvBmIlAupCgdiVUocDBBATlRx9D+qYewTUGUM9J0iJZQABRC1H09XhAAFETUfTzeEAAURtR8McbgLEZ2nlcIAAooWjGaAZzZVWDgcIIFo5mqYOBwggWjqaZg4HCCBaO5ochxMq6xkAAogejqa6wwECiF6OJsXhSoQcDhBA9HQ01RwOEED0djRVHA4QQAPhaJjDQRXQHnIcDhBATFDB/wOEXYjwIIbDAQJooEKaVIDicIAAGiqORnE4QAANJUfDHQ4QQEPN0eCmL0AAMQ01B4NogABiGmoOBnEAAogJymEcILyHVAeDAEAADVRIg5qgZ4gop7F23wACiGmAHLwb2qIjq78JEEBMQ83BIAAQQExDzcEgABBATEPNwSAAEEBMg8jBZ6EtP0JjJgwAAcQ0iBxM9BgfQAAxDTUHgwBAADENNQeDAEAAMQ01B4MAQABR29FK0JqOZg4GAYAAYqKyg4kZbKHIwSAAEEBMQ83BIAAQQNSavgBNS8wiQh1V5lwAAgwAYcaCSQkyYvAAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-big-green-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGhSURBVHjaYmy82cBABVBOpLpZQPyeUssAAoiFgTpgNRDvBmIlAupCgdiVUocDBBATlRx9D+qYewTUGUM9J0iJZQABRC1H09XhAAFETUfTzeEAAURtR8McbgLEZ2nlcIAAooWjGaAZzZVWDgcIIFo5mqYOBwggWjqaZg4HCCBaO5ochxMq6xkAAogejqa6wwECiF6OJsXhSoQcDhBA9HQ01RwOEED0djRVHA4QQAPhaJjDQRXQHnIcDhBATFDB/wOEXYjwIIbDAQJooEKaVIDicIAAGiqORnE4QAANJUfDHQ4QQEPN0eCmL0AAMQ01B4NogABiGmoOBnEAAogJymEcILyHVAeDAEAADVRIg5qgZ4gop7F23wACiGmAHLwb2qIjq78JEEBMQ83BIAAQQExDzcEgABBATEPNwSAAEEBMg8jBZ6EtP0JjJgwAAcQ0iBxM9BgfQAAxDTUHgwBAADENNQeDAEAAMQ01B4MAQABR29FK0JqOZg4GAYAAYqKyg4kZbKHIwSAAEEBMQ83BIAAQQNSavgBNS8wiQh1V5lwAAgwAAcCCsTPvC/4AAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-big-grey-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGhSURBVHjaYuzsmcBABVBOpLpZQPyeUssAAoiFgTpgNRDvBmIlAupCgdiVUocDBBATlRx9D+qYewTUGUM9J0iJZQABRC1H09XhAAFETUfTzeEAAURtR8McbgLEZ2nlcIAAooWjGaAZzZVWDgcIIFo5mqYOBwggWjqaZg4HCCBaO5ochxMq6xkAAogejqa6wwECiF6OJsXhSoQcDhBA9HQ01RwOEED0djRVHA4QQAPhaJjDQRXQHnIcDhBATFDB/wOEXYjwIIbDAQJooEKaVIDicIAAGiqORnE4QAANJUfDHQ4QQEPN0eCmL0AAMQ01B4NogABiGmoOBnEAAogJymEcILyHVAeDAEAADVRIg5qgZ4gop7F23wACiGmAHLwb2qIjq78JEEBMQ83BIAAQQExDzcEgABBATEPNwSAAEEBMg8jBZ6EtP0JjJgwAAcQ0iBxM9BgfQAAxDTUHgwBAADENNQeDAEAAMQ01B4MAQABR29FK0JqOZg4GAYAAYqKyg4kZbKHIwSAAEEBMQ83BIAAQQNSavgBNS8wiQh1V5lwAAgwAl0uCfFFm6vQAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-big-orange-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGhSURBVHjaYvy/rZCBCqCcSHWzgPg9pZYBBBALA3XAaiDeDcRKBNSFArErpQ4HCCAmKjn6HtQx9wioM4Z6TpASywACiFqOpqvDAQKImo6mm8MBAojajoY53ASIz9LK4QABRAtHM0AzmiutHA4QQLRyNE0dDhBAtHQ0zRwOEEC0djQ5DidU1jMABBA9HE11hwMEEL0cTYrDlQg5HCCA6OloqjkcIIDo7WiqOBwggAbC0TCHgyqgPeQ4HCCAmKCC/wcIuxDhQQyHAwTQQIU0qQDF4QABNFQcjeJwgAAaSo6GOxwggIaao8FNX4AAYhpqDgbRAAHENNQcDOIABBATlMM4QHgPqQ4GAYAAGqiQBjVBzxBRTmPtvgEEENMAOXg3tEVHVn8TIICYhpqDQQAggJiGmoNBACCAmIaag0EAIICYBpGDz0JbfoTGTBgAAohpEDmY6DE+gABiGmoOBgGAAGIaag4GAYAAYhpqDgYBgACitqOVoDUdzRwMAgABxERlBxMz2EKRg0EAIICYhpqDQQAggKg1fQGalphFhDqqzLkABBgAxkqC/fyibFUAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-big-purple-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGhSURBVHjaYjwdsJGBCqCcSHWzgPg9pZYBBBALA3XAaiDeDcRKBNSFArErpQ4HCCAmKjn6HtQx9wioM4Z6TpASywACiFqOpqvDAQKImo6mm8MBAojajoY53ASIz9LK4QABRAtHM0AzmiutHA4QQLRyNE0dDhBAtHQ0zRwOEEC0djQ5DidU1jMABBA9HE11hwMEEL0cTYrDlQg5HCCA6OloqjkcIIDo7WiqOBwggAbC0TCHgyqgPeQ4HCCAmKCC/wcIuxDhQQyHAwTQQIU0qQDF4QABNFQcjeJwgAAaSo6GOxwggIaao8FNX4AAYhpqDgbRAAHENNQcDOIABBATlMM4QHgPqQ4GAYAAGqiQBjVBzxBRTmPtvgEEENMAOXg3tEVHVn8TIICYhpqDQQAggJiGmoNBACCAmIaag0EAIICYBpGDz0JbfoTGTBgAAohpEDmY6DE+gABiGmoOBgGAAGIaag4GAYAAYhpqDgYBgACitqOVoDUdzRwMAgABxERlBxMz2EKRg0EAIICYhpqDQQAggKg1fQGalphFhDqqzLkABBgAk5mCo9lxBuAAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-big-red-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGhSURBVHjaYvyY18RABVBOpLpZQPyeUssAAoiFgTpgNRDvBmIlAupCgdiVUocDBBATlRx9D+qYewTUGUM9J0iJZQABRC1H09XhAAFETUfTzeEAAURtR8McbgLEZ2nlcIAAooWjGaAZzZVWDgcIIFo5mqYOBwggWjqaZg4HCCBaO5ochxMq6xkAAogejqa6wwECiF6OJsXhSoQcDhBA9HQ01RwOEED0djRVHA4QQAPhaJjDQRXQHnIcDhBATFDB/wOEXYjwIIbDAQJooEKaVIDicIAAGiqORnE4QAANJUfDHQ4QQEPN0eCmL0AAMQ01B4NogABiGmoOBnEAAogJymEcILyHVAeDAEAADVRIg5qgZ4gop7F23wACiGmAHLwb2qIjq78JEEBMQ83BIAAQQExDzcEgABBATEPNwSAAEEBMg8jBZ6EtP0JjJgwAAcQ0iBxM9BgfQAAxDTUHgwBAADENNQeDAEAAMQ01B4MAQABR29FK0JqOZg4GAYAAYqKyg4kZbKHIwSAAEEBMQ83BIAAQQNSavgBNS8wiQh1V5lwAAgwAuT+CuHezsh4AAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-medium-cyan-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAYAAAAGRPQsAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAACkSURBVHjaYmSY95ABD5gJxGlI/FlAnI5LMUAAMTHgByCNFUj8NKgFWAFAABEyDAQ6iTUQIICIMYxoAwECiFjDiDIQIIBIMYyggQABxALE/xkoA7DYTgcIIBYG6gCQgfcAAoiJSoaB0l8nQACBDGMkEVdgMQickAECiFSXlQNxB64cARBATNQyCAQAAoiJWgaBAEAAMVHLIBAACCBCSYOkUgMgwAAtVR05vXEbNwAAAABJRU5ErkJggg==) no-repeat left center;
}

.arrow-medium-white-down {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAATCAYAAACZZ43PAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADUSURBVHjaYvj//z8DDrz7PwK44FIHEEBMDBQCgACi2ACAAKLYAIAAotgAgACi2ACAAKLYAIAAAhnQAcSCZOhNA2IXgAACGWAMxLtJNASkeSaIARBAMC+QYghcMwgABBByGBhDvYMPYKgBCCCQAatxmY5FM7Irz4IwQADB0nTaf1QwEy0vlAPxOyT+GSAWBOkFCCDkjIFuCC4A1wzCAAGEnrvSSNEMwgABhC2LphGrGYQBAghXWZBGjGYQBgggBjwFShohzSAMEED4DADhUHyaQRggwAAG7Lcs4ZhzXAAAAABJRU5ErkJggg==) no-repeat left center;
}

.arrow-medium-white-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAYAAAAGRPQsAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAB7SURBVHjaYvj//z8DHjzzPyqYiU89QAAxEDAMhMuJNRAggIgxjGgDAQKIWMOIMhAggEgxjKCBAAFEqmF4DQQIIIb/1AFgAwECiPE/2EiqgAqAAKKqywACiKphBhBAVI1NgACiajoDCCCq5gCAAKJq3gQIIAZqlhoAAQYABABzdb78BQIAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-medium-white-up {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAATCAYAAACZZ43PAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADDSURBVHjaYvj//z8DHhwKxIL41AAEED7Naf8h4Aw+QwACiJDm/4QMAQggYjTjNQQggIjVjNMQgAAiRTNWQwACCJfmmUC8G4lfDsTvsBkCEEC4NDOgGeACxMbYDAEIIHSFM5G8hG4AAxZDXAACiIkBAc4CcQUDfoChBiCAmJAkXIH4PQNhMAuI02EcgABigWruJFIzsiFgABBA+JIytjDAwAABxMRAIQAIIIoNAAggig0ACCCKDQAIIIoNAAggig0ACDAAD8Eo88FqmMsAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-small-black-left {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAEBJREFUeNpiYGJgYEDDoUD8H4o7sMjj1VCOTQO6JqI0IGsiWgNMUzmSBmIw2NB7DKQCcp1HdkCQHeRERy5AgAEAHCIkIB+arioAAAAASUVORK5CYII=) no-repeat left center;
}

.arrow-small-black-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAABWSURBVHjaYmBiYGDAgjuA+D8Uh6LLAwQQAw5NIFyOSyNAAOHThFMjQAAR0oRVI0AAoQsSg0MBAgiskUSgBBBAZDkPIIDICgiAACIryAECiIGcyAUIMADbZSNCKm7EfgAAAABJRU5ErkJggg==) no-repeat left center;
}

.arrow-small-cyan-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAB5SURBVHjaYmSY95ABC+gA4nIoOwyIVyNLAgQQEwN2UAHFILAKiEORJQECCJcmEOjEpREggPBpwqkRIIBYoG7vYCAOgDSGAQQQEwPpQAkggJigTmDEgyuQNIBCshMggAjZhOx0eNADBBATqRpAACCAWHBowBu5AAEGAGTxE45363tmAAAAAElFTkSuQmCC) no-repeat left center;
}

// muß nach .arrow-small-cyan-right stehen
// @see _events.scss
.arrow-small-cyan-left {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGJJREFUeNpiZJj3kAENhALxKii7E4gr0BWw4NFQAdWEAZhI1YCsiWgNME3lSBpAoAOI/+PB5SBN9xhIBCBNq4E4DEkM5DxGPLgT5idkjR1QJzMQCgiSNDKh8dE1dmDTBBBgACiBG6aXowEbAAAAAElFTkSuQmCC) no-repeat left center;
}

.arrow-small-grey-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAB5SURBVHjaYpw7fxEDFtABxOVQdhgQr0aWBAggJgbsoAKKQWAVEIciSwIEEC5NINCJSyNAAOHThFMjQACxQN3ewUAcAGkMAwggJgbSgRJAADFBncCIB1cgaQCFZCdAABGyCdnp8KAHCCAmUjWAAEAAseDQgDdyAQIMACnME+0VT8C9AAAAAElFTkSuQmCC) no-repeat left center;
}

.arrow-small-lightcyan-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAB5SURBVHjaYmy7/Y8BC+gA4nIoOwyIVyNLAgQQEwN2UAHFILAKiEORJQECCJcmEOjEpREggPBpwqkRIIBYoG7vYCAOgDSGAQQQEwPpQAkggJigTmDEgyuQNIBCshMggAjZhOx0eNADBBATqRpAACCAWHBowBu5AAEGADN4FG7C+BynAAAAAElFTkSuQmCC) no-repeat left center;
}

.arrow-small-white-left {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAEFJREFUeNpi+P//PwMaDv2PAB1Y5BnwaSjHpgFdE1EakDURrQGmqfw/aaCciYGB4R4DqYBc55EdEGQHOdGRCxBgANsrgOZspxuFAAAAAElFTkSuQmCC) no-repeat left center;
}

.arrow-small-white-right {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAABVSURBVHjaYvj//z8DFtzxHwFC0eUBAogBhyYQLselESCA8GnCqREggAhpwqoRIIDQBYkBoQABxMRAOlACCCCynAcQQGQFBEAAkRXkAAHEQE7kAgQYAJp9gAhYoBajAAAAAElFTkSuQmCC) no-repeat left center;
}


.triangle-down-cyan {
	background: url(../../Public/Images/StyleSheets/triangle-down-cyan.png)  no-repeat left center;
}


// ***** Clearfix **************************************************************
.cf:before,
.cf:after {
	content: '';
	display: table;
}

.cf:after {
	clear: both;
}



// ***** Error ******************************************************************
.error {
	color: $errorColor;
	font-weight: bold;
}
